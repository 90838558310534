<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <CouponForm ref="form"></CouponForm>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item" v-if="!isClientChild">
            <router-link class="btn btn-white" :to="{ name: 'EventCoupon' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="register" button-text="作成する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CouponForm from '../../Shop/Coupon/Form.vue';
import nav from '@/mixins/nav/coupon-event';

export default {
  name: 'EventCouponRegister',
  data: function() {
    return {
      pageName: 'クーポン',
    };
  },
  components: {
    CouponForm,
  },
  mixins: [nav],
  computed: {
    ...mapGetters({
      isClientChild: 'auth/isClientChild'
    }),
  },
  methods: {
    register() {
      this.$refs.form.handleSubmit();
    },
  },
};
</script>
