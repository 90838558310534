import { mapGetters } from 'vuex';

export default {
  data: function() {
    return {
      navs: [
        {
          id: '01',
          name: 'クーポン一覧',
          path: '/event/coupon',
          routeName: 'EventCoupon',
          includes: false,
        },
        {
          id: '02',
          name: '利用実績',
          path: '/event/coupon/use',
          routeName: 'EventCouponUse',
          includes: false,
        },
        {
          id: '03',
          name: 'クーポン作成',
          path: '/event/coupon/register',
          routeName: 'EventCouponRegister',
          includes: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isClientChild: 'auth/isClientChild',
      hasShopRole: 'auth/hasShopRole',
    }),
    pageNavs() {
      if (this.isClientChild || !this.hasShopRole) return this.navs.slice(0, 2);
      return this.navs;
    }
  },
};
